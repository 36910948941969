import { APPLICATION_QUESTIONS } from "./constants";
export const getApplicationAnswers = applicationAnswers => {
  return new Map(applicationAnswers.filter(answer =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Object.values(APPLICATION_QUESTIONS).includes(answer.question)).map(answer => [answer.question, answer.answer]));
};
export const getProjectURIComponents = id => {
  const split = id.split(":");
  if (split.length < 3) {
    return {
      chainId: "",
      registryAddress: "",
      id: ""
    };
  }
  return {
    chainId: split[0],
    registryAddress: split[1],
    id: split[2]
  };
};